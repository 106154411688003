import { useEffect, useState } from "react";

const useFetchCities = () => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch(
          "https://servicodados.ibge.gov.br/api/v1/localidades/municipios"
        );
        const data = await response.json();
        const cityNames = data.map((city) => city.nome);
        setCities(cityNames);
      } catch (error) {
        console.error("Erro ao buscar cidades:", error);
      }
    };

    fetchCities();
  }, []);

  return cities;
};

export default useFetchCities;
