import axios from "axios";
import { initializeApp } from "firebase/app";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, sendPasswordResetEmail, updatePassword } from "firebase/auth";
import { addDoc, arrayUnion, deleteDoc, collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, serverTimestamp, setDoc, startAfter, Timestamp, updateDoc, where, deleteField } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import isViewedProperty from "./isViewedProperty";
import addViewedProperty from "./addViewedProperty";
const serverURL = "https://us-central1-alugueimoveis-6808c.cloudfunctions.net"
// const serverURL = "http://127.0.0.1:5001/alugueimoveis-d0aaa/us-central1"
const firebaseConfig = {
  apiKey: "AIzaSyDNAVCyOKKYlbpfozI3VK_JbDwmT82TIMk",
  authDomain: "alugueimoveis-6808c.firebaseapp.com",
  projectId: "alugueimoveis-6808c",
  storageBucket: "alugueimoveis-6808c.appspot.com",
  messagingSenderId: "365485229839",
  appId: "1:365485229839:web:02ef999bee2a2d62cce07a",
  measurementId: "G-LBQ2T49127"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
auth.languageCode = 'pt'; 
export { auth, db, serverURL };

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email, {
      url: 'https://alugueimoveis.com/entrar',
    });
    return { success: true, message: 'E-mail de redefinição de senha enviado com sucesso!' };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export async function togglePropertyActive(propertyId) {
  const propertyRef = doc(db, 'properties', propertyId);

  try {
      const docSnap = await getDoc(propertyRef);
      if (docSnap.exists()) {
          const currentActiveStatus = docSnap.data().active;
          await updateDoc(propertyRef, {
              active: !currentActiveStatus
          });
      }
  } catch (error) {
      console.error(error);
  }
}

export async function toggleDeleteProperty(propertyId) {
  const propertyRef = doc(db, 'properties', propertyId);

  try {
      const docSnap = await getDoc(propertyRef);
      if (docSnap.exists()) {
          const currentData = docSnap.data();
          const currentStatus = currentData.active;
          const currentDeleted = currentData.deletedAt
          await updateDoc(propertyRef, {
            deletedAt: currentDeleted ? null : true,
            active: !currentStatus
          });
      }
  } catch (error) {
      console.error(error);
  }
}

export async function deleteProperty(propertyId) {
  const propertyRef = doc(db, 'properties', propertyId);

  try {
      const docSnap = await getDoc(propertyRef);
      if (docSnap.exists()) {
          await updateDoc(propertyRef, {
            deletedAt: true,
            active: false
          });
      }
  } catch (error) {
      console.error(error);
  }
}

export async function getNotifications(userId) {
  const result = axios.post(`${serverURL}/fetchNotificationsByUserId`, {
    userId,
    lastDoc: null
  })
  return result
}

export async function toggleFavorite(userId, propertyId) {
  axios.post(`${serverURL}/toggleFavorite`, {
    userId, 
    propertyId
  })
}

export const uploadProfileImage = async (userId, file) => {
  try {
      const formData = new FormData();
      formData.append("image", file); // Passa o arquivo diretamente
      formData.append("data", JSON.stringify({ id: userId }));

      const result = await axios.post(
          `${serverURL}/updateProfileUser`,
          formData,
          {
              headers: {
                  "Content-Type": "multipart/form-data"
              }
          }
      );
      const userData = result.data.data;
      return userData.photoURL;
  } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      return error;
  }
};


export function updateUserInfo(data) {
  const userDocRef = doc(db, "users", data.uid);

  return getDoc(userDocRef)
    .then((userDoc) => {
      if (userDoc.exists()) {
        const updatedData = {
          name: data.name,
          telephone: data.telephone,
          document: data.document,
          creci: data.creci || false,
          myLocation: {
            cep: data.myLocation?.cep,
            city: data.myLocation?.city,
            street: data.myLocation?.street,
            state: data.myLocation?.state,
            neightboard: data.myLocation?.neightboard,
            number: data.myLocation?.number,
            complement: data.myLocation?.complement,
          },
        };

        return updateDoc(userDocRef, updatedData).then(() => {
          return { success: true };
        });
      } else {
        console.error("Nenhum documento encontrado!");
        return null;
      }
    })
    .catch((error) => {
      console.error("Erro ao buscar ou atualizar dados do usuário:", error);
      throw error;
    });
}

export async function getPropertyData(id){
  try {
    const propertyRef = doc(db, "properties", id);
    const result = await getDoc(propertyRef);

    if (result.exists()) {
        var fetchedData = {
            id: result.id, 
            ...result.data()
        };
        
        const userRef = doc(db, "users", fetchedData.ownerId);
        const userResult = await getDoc(userRef);
        if (userResult.exists()) {
            let advertiser = userResult.data();
            let {
                document, myLocation, favorites, 
                newsletter, notifications_enabled,
                properties, type,
                ...rest
            } = advertiser 

            fetchedData = {
                ...fetchedData,
                advertiser: rest,
            }
        }

        return fetchedData;
        
    } else {
        console.log("Nenhum documento encontrado com esse ID.");
        return null;
    }
  } catch (error) {
      console.error("Erro ao obter documento:", error);
  }
}

export async function getAllBlogPosts() {
  const blogCollectionRef = collection(db, "blogs"); 
  const querySnapshot = await getDocs(blogCollectionRef);

  const blogPosts = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));

  return blogPosts;
}

export async function updateBlogById(id, updatedData) {
  try {
    const blogRef = doc(db, "blogs", id);
    const blogSnap = await getDoc(blogRef);

    if (blogSnap.exists()) {
      await updateDoc(blogRef, updatedData);
      alert("Blog atualizado com sucesso!");
    } else {
      const newBlogRef = await addDoc(collection(db, "blogs"), updatedData);
      alert(`Blog criado com sucesso! ID: ${newBlogRef.id}`);
    }
  } catch (error) {
    console.error("Erro ao atualizar ou criar o blog: ", error);
  }
}

export const deleteBlogById = async (id) => {
  try {
      const blogRef = doc(db, "blogs", id); // Obtém a referência do documento
      await deleteDoc(blogRef); // Deleta o documento
      console.log("Blog excluído com sucesso");
  } catch (error) {
      console.error("Erro ao excluir o blog: ", error);
  }
};

export async function getMiscDataByType() {
  try {
      // Cria uma referência à coleção "misc"
      const miscCollectionRef = collection(db, "misc");

      // Cria a consulta para buscar onde 'type' seja igual a 'dados gerais'
      const q = query(miscCollectionRef, where("type", "==", "dados gerais"));

      // Executa a consulta e obtém os documentos
      const querySnapshot = await getDocs(q);

      // Verifica se encontrou algum documento e retorna os dados
      const miscData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
      }));

      // Caso nenhum documento seja encontrado
      if (miscData.length === 0) {
          console.log("Nenhum documento encontrado.");
      }

      return miscData;
  } catch (error) {
      console.error("Erro ao buscar dados: ", error);
  }
}

export async function saveMiscById(id, updatedData) {
  try {
      const blogRef = doc(db, "misc", updatedData.id); // "blogs" é a coleção onde o blog está armazenado
      await updateDoc(blogRef, updatedData); // Atualiza os dados no Firestore
      //console.log("Blog atualizado com sucesso!");
  } catch (error) {
      console.error("Erro ao atualizar o misc: ", error);
  }
}

export async function changePassword (currentPassword, newPassword) {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
      throw new Error("Nenhum usuário está logado.");
  }

  try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      await updatePassword(user, newPassword);

      return "Senha alterada com sucesso!";
  } catch (error) {
      if (error.code === "auth/wrong-password") {
          throw new Error ("Senha atual incorreta.");
      } else if (error.code === "auth/weak-password") {
          throw new Error ("A nova senha é muito fraca.");
      } else {
          throw new Error ("Erro ao alterar a senha. Tente novamente mais tarde.");
      }
  }
};

export async function getPropertyList(filters) {
  const propertiesCollection = collection(db, 'properties');

  let constraints = [
    where('active', '==', true),
    where('status', '==', 'ACTIVE'),
    where('deletedAt', '==', null),
  ];

  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      if (filter.filterName && filter.value !== undefined) {
        constraints.push(where(filter.filterName, '==', filter.value));
      }
    });
  }

  constraints.push(orderBy('createdAt', 'desc'));
  constraints.push(limit(20))

  const activePropertiesQuery = query(propertiesCollection, ...constraints);

  const result = await getDocs(activePropertiesQuery);
  return result.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));
}

getPropertyList([{ filterName: "planId", value: "qUwK4ftcZFeKXXIl2eph" }]).then(result => {
  console.log(result)
})

export async function getPropertiesLancamento() {
  const propertiesCollection = collection(db, 'properties');
  const activePropertiesQuery = query(
      propertiesCollection, 
      where('active', '==', true),
      where('status', "==", "ACTIVE"),
      where('planId', "==", "ziK3BUUd5mPCBC4NW5n9")
  );

  const result = await getDocs(activePropertiesQuery)

  return result.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
      }));
}




export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                  const { latitude, longitude } = position.coords;
                  resolve({ latitude, longitude });
              },
              (error) => {
                  reject(error.message || "Não foi possível obter a localização.");
              }
          );
      } else {
          reject("Geolocalização não é suportada pelo navegador.");
      }
  });
};

export const clearViewHistory = async (userId) => {
  if (userId === null) return;

  try {
    localStorage.removeItem("viewedProperties");

    const viewsCollectionRef = collection(db, "views");
    const querySnapshot = await getDocs(viewsCollectionRef);

    const updatePromises = querySnapshot.docs.map(async (docSnap) => {
      const data = docSnap.data();
      const viewers = data.viewers || [];

      if (viewers.includes(userId)) {
        const updatedViewers = viewers.filter((viewer) => viewer !== userId);
        const updatedCount = Math.max((data.count || 0) - 1, 0);

        await updateDoc(docSnap.ref, {
          viewers: updatedViewers,
          count: updatedCount,
        });
      }
    });

    await Promise.all(updatePromises);

    return true;
  } catch (error) {
    return false
  }
};


export const trackPropertyView = async (userId, propertyId) => {
  if (userId === null) return;
  if (isViewedProperty(propertyId)) return;

  try {
      const result = await axios.post(`${serverURL}/trackProperty`, {
        userId: userId, 
        propertyId: propertyId,
      })
      addViewedProperty(propertyId)
  } catch (error) {
      console.error("Erro ao registrar visualização:", error);
  }
};

export const getPropertiesViewedByUser = async (userId, page = 1, pageSize) => {
  try {
    const viewsCollectionRef = collection(db, "views");
    const viewsQuery = query(viewsCollectionRef, where("viewers", "array-contains", userId));
    const viewsSnapshot = await getDocs(viewsQuery);
    const propertyIds = viewsSnapshot.docs.map((doc) => doc.id);
    if (propertyIds.length === 0) return { properties: [], total: 0 };

    const propertiesCollectionRef = collection(db, "properties");
    const propertiesQuery = query(
      propertiesCollectionRef,
      where("active", "==", true),
      where("deletedAt", "==", null)
    );

    const allPropertiesSnapshot = await getDocs(propertiesQuery);
    const allProperties = allPropertiesSnapshot.docs
      .filter((propertyDoc) => propertyDoc.exists() && propertyIds.includes(propertyDoc.id))
      .map((propertyDoc) => ({
        id: propertyDoc.id,
        ...propertyDoc.data(),
      }));

    const total = allProperties.length;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedProperties = allProperties.slice(startIndex, endIndex);

    return { properties: paginatedProperties, total };
  } catch (error) {
    console.error("Erro ao buscar imóveis visualizados:", error);
    throw error;
  }
};

export const getFavoritesPropertiesByUser = async (userId, page = 1, pageSize) => {
  try {
    const propertiesCollectionRef = collection(db, "properties");
    const propertiesQuery = query(
      propertiesCollectionRef,
      where("likedBy", "array-contains", userId), 
      where("active", "==", true), 
      where("deletedAt", "==", null) 
    );

    const propertiesSnapshot = await getDocs(propertiesQuery);
    const allProperties = propertiesSnapshot.docs.map((propertyDoc) => ({
      id: propertyDoc.id,
      ...propertyDoc.data(),
    }));

    const total = allProperties.length;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedProperties = allProperties.slice(startIndex, endIndex);

    return { properties: paginatedProperties, total };
  } catch (error) {
    console.error("Erro ao buscar imóveis favoritos:", error);
    throw error;
  }
};

export const getPropertiesByUser = async (userId, page = 1, pageSize) => {
  try {
    const propertiesCollectionRef = collection(db, "properties");
    const propertiesQuery = query(
      propertiesCollectionRef,
      where("ownerId", "==", userId), 
      where("deletedAt", "==", null) 
    );

    const propertiesSnapshot = await getDocs(propertiesQuery);
    const allProperties = propertiesSnapshot.docs.map((propertyDoc) => ({
      id: propertyDoc.id,
      ...propertyDoc.data(),
    }));

    const total = allProperties.length;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedProperties = allProperties.slice(startIndex, endIndex);

    return { properties: paginatedProperties, total };
  } catch (error) {
    console.error("Erro ao buscar imóveis favoritos:", error);
    throw error;
  }
};

export const reportProperty = async (reporter, propertyId, propertyImage) => {
  if (reporter === null) return;

  try {
    const moment = Timestamp.now(); // Usa o Timestamp do Firebase

    const report = {
      reporter: reporter.email,
      uidReporter: reporter.uid,
      property: propertyId,
      propertyImage: propertyImage,
      moment: moment,
      status: "pending"
    };

    const reportRef = await addDoc(collection(db, "reports"), report);

    return { success: true, reportId: reportRef.id };
  } catch (error) {
    return { success: false, error };
  }
};

export const sendNewAlertMessage = async(data) => {
  const result = axios.post(`${serverURL}/addAlert`, data)
}

export async function getDocumentsByMonthAndYear(month, year) {
  // Criar datas de início e fim
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 1);

  // Converter para Timestamp do Firestore
  const startTimestamp = Timestamp.fromDate(startDate);
  const endTimestamp = Timestamp.fromDate(endDate);

  // Criar a query correta
  const q = query(
      collection(db, "properties"),
      where("createdAt", ">=", startTimestamp),
      where("createdAt", "<", endTimestamp)
  );

  try {
      const querySnapshot = await getDocs(q);
      let results = [];
      querySnapshot.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() });
      });

      return results;
  } catch (error) {
      console.error("Erro ao buscar documentos:", error);
  }
}

export async function getActualPriceOfHighlight() {
  const docRef = doc(db, "plans", "OVjjz0xxONzOqigRmKdA");
  const docSnap = await getDoc(docRef); 

  if (docSnap.exists()) {
      console.log("Documento encontrado:", docSnap.data());
      return docSnap.data().price;
  }
}


//testar
export async function updatePropertyOwnerByEmail(email, propertyId) {
  try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
          return "teste";
          throw new Error("Usuário não encontrado.");
      }

      const userDoc = querySnapshot.docs[0]; 
      const userId = userDoc.id;

      const propertyRef = doc(db, "properties", propertyId);
      await updateDoc(propertyRef, { ownerId: userId });

      return `Propriedade ${propertyId} agora pertence a ${userId}`;
  } catch (error) {
      console.error("Erro ao atualizar propriedade:", error);
  }
}

export async function activatePropertyPlan(propertyId) {
  try {
      const propertyRef = doc(db, "properties", propertyId);
      const propertySnap = await getDoc(propertyRef);

      if (!propertySnap.exists()) 
          throw new Error("Imóvel não encontrado.");
      
      const propertyData = propertySnap.data();
      const planId = propertyData.planId;

      if (!planId) 
          throw new Error("Imóvel não possui um plano associado.");

      const planRef = doc(db, "plans", planId);
      const planSnap = await getDoc(planRef);

      if (!planSnap.exists()) 
          throw new Error("Plano não encontrado.");
      
      const planData = planSnap.data();
      const activeDays = planData.activeDays;

      if (!activeDays || isNaN(activeDays)) 
          throw new Error("Plano não possui um número válido de dias ativos.");

      // 3. Calcular a nova data de expiração
      const today = new Date();
      const expirationDate = new Date(today);
      expirationDate.setDate(today.getDate() + activeDays); // Adiciona os dias ao timestamp atual

      // Converter para Timestamp do Firestore
      const firestoreTimestamp = Timestamp.fromDate(expirationDate);

      // 4. Atualizar o imóvel com a nova expiração e status ACTIVE
      await updateDoc(propertyRef, {
          expirationDate: firestoreTimestamp,
          status: "ACTIVE"
      });

      return `Propriedade ${propertyId} ativada até ${expirationDate}`;
  } catch (error) {
      console.error("Erro ao ativar plano do imóvel:", error);
  }
}

export async function getFlagsByMonthAndYear(month, year) {
  // Criar datas de início e fim
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 1);

  // Converter para Timestamp do Firestore
  const startTimestamp = Timestamp.fromDate(startDate);
  const endTimestamp = Timestamp.fromDate(endDate);

  // Criar a query correta
  const q = query(
      collection(db, "reports"),
      where("moment", ">=", startTimestamp),
      where("moment", "<", endTimestamp)
  );

  try {
      const querySnapshot = await getDocs(q);
      let results = [];
      querySnapshot.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() });
      });

      return results;
  } catch (error) {
      console.error("Erro ao buscar documentos:", error);
  }
}

export async function deleteFlag(id) {
  if (!id) return; // Evita chamadas desnecessárias

  try {
      await deleteDoc(doc(db, "reports", id));
      return { success: true };
  } catch (error) {
      console.error("Erro ao excluir o documento:", error);
      return { success: false, error };
  }
}

export async function setFlagToPending(id) {
  if (!id) return { success: false, error: "ID inválido" };

  try {
      // Referência ao documento do report
      const reportRef = doc(db, "reports", id);
      const reportSnap = await getDoc(reportRef);

      if (!reportSnap.exists()) {
          return { success: false, error: "Report não encontrado" };
      }

      const reportData = reportSnap.data();
      const propertyId = reportData.property; // ID da propriedade vinculada

      if (!propertyId) {
          return { success: false, error: "ID da propriedade não encontrado" };
      }

      // Atualiza o status do report para "pending"
      await updateDoc(reportRef, { status: "pending" });

      // Atualiza a propriedade correspondente para ativa novamente
      const propertyRef = doc(db, "properties", propertyId);
      await updateDoc(propertyRef, {
          active: true,
          deletedAt: null, // Remove a data de exclusão
      });

      console.log(`Documento ${id} alterado para pending. Propriedade ${propertyId} reativada.`);
      return { success: true };
  } catch (error) {
      console.error("Erro ao atualizar o status:", error);
      return { success: false, error };
  }
}

export async function setFlagToApproved(id) {
  if (!id) return { success: false, error: "ID inválido" };

  try {
      // Referência ao documento do report
      const reportRef = doc(db, "reports", id);
      const reportSnap = await getDoc(reportRef);

      if (!reportSnap.exists()) {
          return { success: false, error: "Report não encontrado" };
      }

      const reportData = reportSnap.data();
      const propertyId = reportData.property; // ID da propriedade

      if (!propertyId) {
          return { success: false, error: "ID da propriedade não encontrado" };
      }

      // Atualiza o status do report para "approved"
      await updateDoc(reportRef, { status: "approved" });

      // Atualiza a propriedade correspondente
      const propertyRef = doc(db, "properties", propertyId);
      await updateDoc(propertyRef, {
          active: false,
          deletedAt: new Date(), // Timestamp atual
      });

      console.log(`Documento ${id} aprovado. Propriedade ${propertyId} desativada.`);
      return { success: true };
  } catch (error) {
      console.error("Erro ao atualizar o status:", error);
      return { success: false, error };
  }
}


export async function updatePlan(data) {
  const planId = data.id; // Pegando o ID do plano a partir de `data`

  if (!planId || !data) return;

  try {
      const planRef = doc(db, "plans", planId); // Referência ao documento do plano
      const { id, ...planData } = data; // Remover o id antes de atualizar o documento
      await updateDoc(planRef, planData); // Atualiza os dados no Firestore
      console.log(`Plano ${planId} atualizado com sucesso.`);
  } catch (error) {
      console.error("Erro ao atualizar o plano:", error);
  }
}

export async function freeTrialUsed(userId) {
  if (!userId) {
      throw new Error("O ID do usuário é obrigatório.");
  }

  try {
      const userRef = doc(db, "users", userId);

      await updateDoc(userRef, {
          freeTrialUsed: true
      });

      console.log(`Teste grátis registrado para o usuário ${userId}`);
  } catch (error) {
      console.error("Erro ao atualizar o teste grátis:", error);
      throw error;
  }
}

export async function getAllUsers() {
  const usersRef = collection(db, "users");
  const querySnapshot = await getDocs(usersRef);

  if (querySnapshot.empty) return []
  
  const users = [];
  querySnapshot.forEach((doc) => {
      users.push({
          id: doc.id,
          ...doc.data()
      });
  });
  return users;
}

export async function toggleDefineAnUserAsAdmin(userId) {
  if (!userId) throw new Error("O ID do usuário é obrigatório.");

  try {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) throw new Error("Usuário não encontrado.");
    
    const userData = userSnap.data();
    const isAdmin = userData.role === "admin";
    if (isAdmin) {
      await updateDoc(userRef, {
        role: deleteField() // Remove a propriedade role
      });
      console.log(`Permissão de admin removida do usuário ${userId}`);
    } else {
      await updateDoc(userRef, {
        role: "admin"
      });
      console.log(`Usuário ${userId} agora é admin`);
    }
  } catch (error) {
    console.error("Erro ao alternar permissão de admin:", error);
    throw error;
  }
}

export async function toggleFreeTrial(userId) {
  if (!userId) throw new Error("O ID do usuário é obrigatório.");

  try {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) throw new Error("Usuário não encontrado.");
    
    const userData = userSnap.data();
    const freeTrialUsed = userData.freeTrialUsed;
    if (freeTrialUsed) {
      await updateDoc(userRef, {
        freeTrialUsed: false 
      });
      console.log(`freeTrialUsed removida do usuário ${userId}`);
    } else {
      await updateDoc(userRef, {
        freeTrialUsed: true
      });
      console.log(`Usuário ${userId} agora tem freeTrialUsed`);
    }
  } catch (error) {
    console.error("Erro ao alternar permissão de admin:", error);
    throw error;
  }
}

export async function getNumberOfUnreadNotifications(userId) {
  const q = query(
    collection(db, "notifications"),
    where("ownerId", "==", userId),
    where("read", "==", false)
  );

  try {
    const querySnapshot = await getDocs(q);
    const count = querySnapshot.size; // Obtém a contagem diretamente, sem criar um array

    if (count > 9) {
      return "+9"
    } else if (count === 0) {
      return null
    } else {
      return count
    }

    //return count > 9 ? "+9" : count;
  } catch (error) {
    console.error("Erro ao buscar notificações:", error);
    return null; // Garante um retorno seguro em caso de erro
  }
}

export async function readNotification(notificationId) {
  try {
    await updateDoc(doc(db, "notifications", notificationId), { read: true });
    return true; 
  } catch (error) {
    console.error("Erro ao marcar a notificação como lida:", error);
    return false; 
  }
}

export async function getLauchsByMonthAndYear(month, year) {
  // Criar datas de início e fim
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 1);

  // Converter para Timestamp do Firestore
  const startTimestamp = Timestamp.fromDate(startDate);
  const endTimestamp = Timestamp.fromDate(endDate);

  // Criar a query correta
  const q = query(
      collection(db, "properties"),
      where("createdAt", ">=", startTimestamp),
      where("createdAt", "<", endTimestamp),
      where("isLaunch", "==", true),
      //where("status", "==", "WAITING"),
  );

  try {
      const querySnapshot = await getDocs(q);
      let results = [];
      querySnapshot.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() });
      });

      return results;
  } catch (error) {
      console.error("Erro ao buscar documentos:", error);
  }
}

// getLauchsByMonthAndYear(3, 2025).then(result => console.log(result))

export async function changeStatusAnPropertyById(id, newStatus) {
  try {
    await updateDoc(doc(db, "properties", id), { status: newStatus });
    return true; 
  } catch (error) {
    console.error("Erro ao marcar a notificação como lida:", error);
    return false; 
  }
}


export async function getPropertiesByIds(propertyArray) {
  const propertyPromises = propertyArray.map(async (item) => {
      const propertyRef = doc(db, "properties", item.propertyId);
      const propertySnap = await getDoc(propertyRef);
      
      if (propertySnap.exists()) {
          return { id: item.propertyId, ...propertySnap.data(), date: item.date };
      } else {
          console.warn(`Imóvel com ID ${item.propertyId} não encontrado.`);
          return null;
      }
  });

  const properties = await Promise.all(propertyPromises);
  return properties.filter(property => property !== null); // Remove valores nulos
}


export async function getCityCoordinates(cityName) {
  const API_KEY = 'AIzaSyDVrDZ19GzGLSdUt4JEEQ1RXQK9N1IE24Y'; // Substitua pela sua chave real
  
  try {
    // Codifica o nome da cidade para URL
    const encodedCity = encodeURIComponent(cityName);
    
    // Faz a requisição para a API Geocoding do Google Maps
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedCity}&key=${API_KEY}`
    );
    
    const data = await response.json();
    
    if (data.status === 'OK' && data.results.length > 0) {
      // Retorna a latitude e longitude do primeiro resultado
      const location = data.results[0].geometry.location;
      return {
        lat: location.lat,
        lng: location.lng
      };
    } 
  } catch (error) {
    console.error('Erro ao obter coordenadas:', error);
    throw error;
  }
}