import styled from "styled-components";
import theme from "../../theme";

const WrapperNavAdmin = styled.div`
    width: 23%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 40px;
    user-select: none;

    .user{
        padding: 20% 10%;
        padding-bottom: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-img{
            width: 40%;

            img {
                width: 100%;
                border-radius: 500px;
            }
        }

        .name{
            font-size: 25px;
            margin-top: 10px;
            font-family: ${theme.fontFamily.bold};
        }

        .type{
            margin-top: -5px;
        }

        .website{
            background-color: #111;
            border-radius: 100px;
            width: 100%;
            color: white;
            text-align: center;
            padding: 3px 0px;
            margin-top: 20px;
        }
    }

    .links {
        display: flex;
        flex-direction: column;

        a {
            display: flex;
            align-items: center;
            gap: 20px;
            color: black;
            text-decoration: none;
            background-color: white;
            padding: 10px 5%;
            padding-left: 15%;
            font-size: 20px;

            &.active {
                background-color: #111;
                color: white;
            }

            svg{
                font-size: 25px;
            }
        }
    }
`

export default WrapperNavAdmin