import { useContext, useEffect, useRef, useState } from "react";
import WrapperRegisterProperty from "./ResigterProperty.styles";
import { FaX, FaXmark } from "react-icons/fa6";
import HouseIcon from "../../imgs/icons/camera_3326034.png"
import { IoIosArrowDown } from "react-icons/io";
import theme from "../../theme";
import { AuthContext } from './../../context/AuthContext';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { FiX } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import ImageUpload from "../../components/image_upload/ImageUpload";
import CustomSelectBox from "../../components/custom_selectbox_a/CustomSelectBoxA";

 
const RegisterPropertyPage = () => {
    const isMobile = window.innerWidth <= theme.breackpoint.mobile_2.replace("px", "")
    const [step, setStep] = useState(0)
    var {currentUser} = useContext(AuthContext)
    const [selectedInputs, setSelectedInputs] = useState({});
    const [bathroomQuantity, setBathroomQuantity] = useState(false)
    const [bedroomQuantity, setBedroomQuantity] = useState(false)
    const [garageQuantity, setGarageQuantity] = useState(false)
    //const [iscondominium, setIsCondominium] = useState(false)
    const [selectboxValue, setSelectBoxValue] = useState(isMobile ? "Imóvel" : "Tipo do Imóvel")
    const [showOptions, setShowOptions] = useState(false)
    const [squareMeter, setSquareMeter] = useState("")
    const [squareMeterConstruction, setSquareMeterConstruction] = useState("")
    const [price, setPrice] = useState("")
    const [condominiumPrice, setCondominiumPrice] = useState("")
    const [IPTU, setIPTU] = useState("")
    const [description, setDescription] = useState("")
    const [CEP, setCEP] = useState("")
    const [city, setCity] = useState("")
    const [street, setStreet] = useState("")
    const [number, setNumber] = useState("")
    const [complement, setComplement] = useState("")
    const [acceptsExchange, setAcceptsExchange] = useState(true)
    const [neightboard, setNeightboard] = useState("")
    const [state, setState] = useState("")
    const [alqueiro, setAlqueiro] = useState("")
    const [checkedPrivacyPolicy, setCheckedPrivacyPolicy] = useState(false)
    const [showContactUser, setShowContactUser] = useState(false)
    const [images, setImages] = useState([])
    const navigate = useNavigate()
    const CEPFeedbackRef = useRef(null)
    
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Para um efeito de scroll suave
            });
        };

        scrollToTop();
    },[])

    const formatPriceToBrl = (value) => {
        let numericValue = value.replace(/\D/g, '');
        
        numericValue = (numericValue / 100).toFixed(2);
        
        return new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(numericValue);
    };

    const handlePrice = (e) => {
        let value = (e.currentTarget.value).replace(/\D/g, '');
        
        setPrice(formatPriceToBrl(value));
    };
    
    const handleAllDataFilledAndPrivacyPolice = () => {
        let property = {
            property: {
                price: formatPrice(),
                bathroom: parseInt(bathroomQuantity),
                bedroom: parseInt(bedroomQuantity),
                cep: CEP,
                city,
                garage: parseInt(garageQuantity),
                images: getImages().length > 0,
                neightboard,
                squareConstruction: parseInt(squareMeterConstruction),
                squareMeter: parseInt(squareMeter),
                state,
                street,
                type: selectboxValue,
                checkedPrivacyPolicy
            }
        }

        const isValid = Object.values(property.property).every(value => {
            if (Array.isArray(value)) {
                return value.length !== "";
            }
            return value !== undefined && value !== null && value !== "" && value !== false;
        });
    
        return isValid && property.property.checkedPrivacyPolicy;
    }

    const handleToggle = (id) => {
      setSelectedInputs((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    };

    function setBathroomQuantityByCheckbox(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.checkbox');

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setBathroomQuantity(value)
    }

    function setBedroomQuantityByCheckbox(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.checkbox');

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setBedroomQuantity(value)
    }

    function setGarageQuantityByCheckbox(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.checkbox');

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setGarageQuantity(value)
    }

    function handleSelectOption(value) {
        setSelectBoxValue(value)
        setShowOptions(false)
    }

    const handleCepBlur = (cep) => {
        let formattedCep = cep.replace(/\D/g, ''); 
        if (formattedCep.length > 5) {
            formattedCep = formattedCep.slice(0, 5) + '-' + formattedCep.slice(5, 9);
        }
    
        formattedCep = formattedCep.slice(0, 9);
        
        setCEP(formattedCep);
        if (formattedCep.length > 8) {
            axios.get(`https://viacep.com.br/ws/${formattedCep.replace("-", "")}/json/`)
            .then(result => {
                const data = result.data;
                if (data.erro) {
                    //CEPFeedbackRef.current.innerText = "CEP incorreto ou inexistente";
                    setCity("")
                    setStreet("")
                    setNeightboard("")
                    setState("")
                    return;
                }
                setCity(data.localidade);
                setStreet(data.logradouro);
                setNeightboard(data.bairro);
                setState(data.uf);
            }).catch(e => {

            })
        } else {
            CEPFeedbackRef.current.innerText = "";
        }
    };
    

    function getImages() {
        const result = images.map((item) => {
            return item.url
        })
 
        return result
    }

    function next() {
        let property = {
            property: {
                ownerId: currentUser,
                price: formatPrice(),
                active: true,
                likedBy: [],
                acceptsExchange,
                bathroom: bathroomQuantity === "4+" ? bathroomQuantity : parseInt(bathroomQuantity),
                bedroom: bedroomQuantity === "4+" ? bedroomQuantity : parseInt(bedroomQuantity),
                cep: CEP,
                city,
                complement,
                description,
                alqueiro,
                garage: garageQuantity === "4+" ? garageQuantity : parseInt(garageQuantity),
                images: getImages(),
                neightboard,
                number,
                otherItems: getSelectedInputs(), 
                squareConstruction: parseInt(squareMeterConstruction),
                squareMeter: parseInt(squareMeter),
                state,
                street,
                type: selectboxValue,
                contactIsVisible: showContactUser
            }
        }
        navigate("/nossos-planos", {state: property})
        
    }
  
    function getSelectedInputs() {
        return inputs
            .map((item) => {
                if (selectedInputs[item.id]) {
                    return item;
                }
                return null; // Retorne null explicitamente para itens não selecionados
            })
            .filter((item) => item !== null); // Filtre apenas os itens válidos
    }

    function formatPrice() {
        let tempPrice = price.replace(/\D/g, '')
        return parseInt(tempPrice);
    }

    const handleSetDescription = event => {
        let value = (event.currentTarget.value).slice(0, 1000)
        setDescription(value)
    }
 
    const handleSetCEP = event => {
        let value = (event.currentTarget.value).slice(0, 9)
        handleCepBlur(value)
    }

    const handleSetBairro = event => {
        let value = (event.currentTarget.value).slice(0, 45)
        setNeightboard(value)
    }

    const handleSetStreet = event => {
        let value = (event.currentTarget.value).slice(0, 45)
        setStreet(value)
    }

    const handleSetCity = event => {
        let value = (event.currentTarget.value).slice(0, 45)
        setCity(value)
    }

    const handleSetState = event => {
        let value = (event.currentTarget.value).slice(0, 45)
        setState(value)
    }

    const handleSetNumber = event => {
        let value = (event.currentTarget.value).slice(0, 9)
        setNumber(value)
    }

    const handleSetComplement = event => {
        let value = (event.currentTarget.value).slice(0, 30)
        setComplement(value)
    }

    const handleSetCondominium = event => {
        let value = (event.currentTarget.value).slice(0, 15)
        setCondominiumPrice(formatPriceToBrl(value))
    }

    const handleSetIPTU = event => {
        let value = (event.currentTarget.value).slice(0, 15)
        setIPTU(formatPriceToBrl(value))
    }

    const handlSetSquareMeter = event => {
        let value = (event.currentTarget.value).slice(0, 10)
        setSquareMeter(value)
    }

    const handlSetAlqueiro = event => {
        let value = (event.currentTarget.value).slice(0, 10)
        setAlqueiro(value)
    }

    const handlSetSquareMeterConstruction = event => {
        let value = (event.currentTarget.value).slice(0, 10)
        setSquareMeterConstruction(value)
    }
      
    const inputs = [
        { id: "academia", label: "Academia" },
        { id: "aceita_pets", label: "Aceita Pets" },
        { id: "churrasqueira", label: "Churrasqueira" },
        { id: "piscina_aquecida", label: "Piscina Aquecida" },
        { id: "alarme", label: "Alarme" },
        { id: "closet", label: "Closet" },
        { id: "portao_eletrico", label: "Portão Elétrico" },
        { id: "aquecedor_solar", label: "Aquecedor Solar" },
        { id: "cozinha_planejada", label: "Cozinha Planejada" },
        { id: "sala_de_jantar", label: "Sala de Jantar" },
        { id: "ar_condicionado", label: "Ar Condicionado" },
        { id: "elevador", label: "Elevador" },
        { id: "varanda", label: "Varanda" },
        { id: "area_gourmet", label: "Área Gourmet" },
        { id: "energia_solar", label: "Energia Solar" },
        { id: "mobiliado", label: "Mobiliado" },
        { id: "area_de_serviço", label: "Área De Serviço" },
        { id: "escritorio", label: "Escritório" },
        { id: "seguranca_24h", label: "Segurança 24h" },
        { id: "armario_embutido", label: "Armário Embutido" },
        { id: "home", label: "Home" },
        { id: "banheira", label: "Banheira" },
        { id: "interfone", label: "Interfone" },
        { id: "lavabo", label: "Lavabo" },
        { id: "cameras_de_seguranca", label: "Câmeras de Segurança" },
        { id: "lavanderia", label: "Lavanderia" },
        { id: "sala_de_tv", label: "Sala de Tv" },
        { id: "cerca_eletrica", label: "Cerca Elétrica" },
        { id: "piscina", label: "Piscina" },
    ];

    const sortedInputs = inputs.sort((a, b) => a.label.localeCompare(b.label));
    
    const options = [
        {option: "Apartamento"},
        {option: "Área Comercial"},
        {option: "Casa"},
        {option: "Casa Comercial"},
        {option: "Casa Em Condomínio"},
        {option: "Chácara"},
        {option: "Galpão/Armazém"},
        {option: "Kitnet/Studio"},
        {option: "Prédio Comercial"},
        {option: "Rancho"},
        {option: "Sala Comercial"},
        {option: "Salão Comercial"},
        {option: "Sítio/Fazenda"},
        {option: "Terreno"},
        {option: "Terreno Comercial"},
        {option: "Terreno Em Condomínio"},
    ]


    return <WrapperRegisterProperty step={step}>
        {step === 0 && 
        <>
            <h1 className="title_session">
                Fale um pouco sobre as <br />
                <span className="bold">especificações do seu imóvel.</span>
            </h1>
            <div className="specs_one">
                <div className="spec">
                    <span className="title">Selecione o tipo de imóvel</span>
                    <CustomSelectBox
                        options={options}
                        showOptions={showOptions}
                        setShowOptions={setShowOptions}
                        selectboxValue={selectboxValue}
                        handleSelectOption={handleSelectOption}
                    />
                </div>
                {!["Apartamento", "Kitnet/Studio", "Sala Comercial", "Sítio/Fazenda"].includes(selectboxValue) && 
                    <div className="spec">
                        <span className="title">Metragem Terreno</span>
                        <div className="inputbox t5">
                            <input type="text" value={squareMeter} onChange={handlSetSquareMeter} />
                            <span className="sufix">M²</span>
                        </div>
                    </div>
                }
                {selectboxValue === "Sítio/Fazenda" && 
                    <div className="spec">
                        <span className="title">Alqueire</span>
                        <div className="inputbox t5">
                            <input type="text" value={alqueiro} onChange={handlSetAlqueiro} />
                        </div>
                    </div>
                }
                {!["Sítio/Fazenda", "Área Comercial", "Terreno", "Terreno Comercial", "Terreno Em Condomínio"].includes(selectboxValue) && 
                    <div className="spec">
                        <span className="title">Metragem Construção</span>
                        <div className="inputbox t5">
                            <input type="text" value={squareMeterConstruction} onChange={handlSetSquareMeterConstruction}/>
                            <span className="sufix">M²</span>
                        </div>
                    </div>
                }   
            </div>
            <div className="specs_two">
                {!["Área Comercial", "Terreno", "Terreno Comercial", "Terreno Em Condomínio", "Sítio/Fazenda"].includes(selectboxValue) && 
                    <div className="spec">
                        <span className="title">Banheiros</span>
                        <div className="checkbox_select">
                            <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("1", e)}>1</span>
                            <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("2", e)}>2</span>
                            <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("3", e)}>3</span>
                            <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("4", e)}>4</span>
                            <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("4+", e)}>4+</span>
                        </div>
                    </div>
                }  
                {!["Sala Comercial", "Sítio/Fazenda", "Área Comercial", "Galpão/Armazém", "Prédio Comercial", "Terreno", "Terreno Comercial", "Terreno Em Condomínio"].includes(selectboxValue) &&
                    <div className="spec">
                        <span className="title">Quartos</span>
                        <div className="checkbox_select">
                            <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("1", e)}>1</span>
                            <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("2", e)}>2</span>
                            <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("3", e)}>3</span>
                            <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("4", e)}>4</span>
                            <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("4+", e)}>4+</span>
                        </div>
                    </div>
                }
                {!["Sítio/Fazenda", "Área Comercial", "Terreno", "Terreno Comercial", "Terreno Em Condomínio"].includes(selectboxValue) && 
                    <div className="spec">
                        <span className="title">Vagas Garagem</span>
                        <div className="checkbox_select">
                            <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("1", e)}>1</span>
                            <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("2", e)}>2</span>
                            <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("3", e)}>3</span>
                            <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("4", e)}>4</span>
                            <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("4+", e)}>4+</span>
                        </div>
                    </div>
                }   
            </div>
            <div className="specs_three">
                <div className="spec">
                    <span className="title">Valor do Aluguel</span>
                    <div className="inputbox">
                        <span className="prefix">R$</span>
                        <input type="text" value={price} onChange={handlePrice} />
                    </div>
                </div>
                {selectboxValue === "Apartamento" && 
                    <>
                        <div className="spec special">
                            <span className="title">Condomínio</span>
                            <div className="inputbox">
                                <span className="prefix">R$</span>
                                <input type="text" placeholder="5.000,00" value={condominiumPrice} onChange={handleSetCondominium} />
                            </div>
                        </div>
                        <div className="spec special">
                            <span className="title">IPTU</span>
                            <div className="inputbox">
                                <span className="prefix">R$</span>
                                <input type="text" placeholder="5.000,00" value={IPTU} onChange={handleSetIPTU} />
                            </div>
                        </div>
                    </>
                }
                <div className="spec">
                    <span className="title">Aceita Permuta</span>
                    <div className="buttons">
                        <span 
                            className={`button ${acceptsExchange ? "actived" : ""}`}
                            onClick={() => setAcceptsExchange(!acceptsExchange)}
                        >
                            Sim</span>
                        <span 
                            className={`button ${!acceptsExchange ? "actived" : ""}`}
                            onClick={() => setAcceptsExchange(!acceptsExchange)}  
                        >
                            Não
                        </span>
                    </div>
                </div>
            </div>
            {!["Terreno", "Terreno Comercial", "Terreno Em Condomínio"].includes(selectboxValue) &&
                <div className="specs_four">
                    {sortedInputs.map((input) => (
                        <div key={input.id} className="spec">
                            <input
                                type="radio"
                                id={input.id}
                                checked={!!selectedInputs[input.id]}
                                onClick={() => handleToggle(input.id)}
                                readOnly
                            />
                            <label htmlFor={input.id}>{input.label}</label>
                        </div>
                    ))}
                </div>
            }
            <div className="specs_five">
                <span className="title">Descreva seu imóvel</span>
                <textarea name="" id="" value={description} onChange={handleSetDescription}></textarea>
                <span className="info">{description.length} de 1000 caracteres</span>
            </div>
            <div className="specs_six">
                <div className="spec">
                    <span className="title">CEP</span>
                    <input type="text" value={CEP} onChange={handleSetCEP} />
                    <span className="feedback" ref={CEPFeedbackRef}></span>
                </div>
                <div className="spec">
                    <span className="title">Cidade</span>
                    <input type="text" className="s" value={city} onChange={handleSetCity} />
                </div>
                <div className="spec">
                    <span className="title">Endereço</span>
                    <input type="text" className="s" value={street} onChange={handleSetStreet} />
                </div>
                <div className="spec">
                    <span className="title">Bairro</span>
                    <input type="text" className="s" value={neightboard} onChange={handleSetBairro} />
                </div>
                <div className="spec">
                    <span className="title">Estado</span>
                    <input type="text" className="s" value={state} onChange={handleSetState} />
                </div>
                {["Terreno", "Terreno Comercial", "Terreno Em Condomínio", "Área Comercial", "Sítio/Fazenda"].includes(selectboxValue) ?
                    <div className="spec">
                        <span className="title">Complemento</span>
                        <div className="input-container">
                            {/* <input type="text" value={number} onChange={handleSetNumber} /> */}
                            <input type="text" value={complement} onChange={handleSetComplement} />
                        </div>
                    </div>
                :
                    <div className="spec">
                        <span className="title">Número/Complemento</span>
                        <div className="input-container">
                            <input type="text" value={number} onChange={handleSetNumber} />
                            <input type="text" value={complement} onChange={handleSetComplement} />
                        </div>
                    </div>
                }
                
            </div>
            <div className="specs_seven">
                <span className="title">Adicione fotos do seu imóvel</span>
                <span className="description title">*Até 20 Imagens</span>
                <ImageUpload images={images} setImages={setImages} />
                <span className="description">Seus dados são mantidos em sigilo por Alugue Imóveis e não são compartilhados com empresas de serviço similares.</span>
            </div>
            <div className="next-container">
                <div className="description" onClick={() => setCheckedPrivacyPolicy(!checkedPrivacyPolicy)}>
                    <div className={`checkbox${checkedPrivacyPolicy ? " checked" : " unchecked"}`}>
                        <FaCheck />
                    </div>
                    Ao enviar um anúncio, você está de acordo com nossa Política de Privacidade.
                </div>
                <div className="description" onClick={() => setShowContactUser(!showContactUser)}>
                    <div className={`checkbox${showContactUser ? " checked" : " unchecked"}`}>
                        <FaCheck />
                    </div>
                    Deseja exíbir seus dados de contato no anúncio?
                </div>
                {handleAllDataFilledAndPrivacyPolice()  ? 
                    <span className="button finish" onClick={next}>
                        Avançar
                    </span>
                    :
                    <span className="button deactive">
                        Avançar
                    </span>
            }
            </div>
        </>
        }
        {step === 1 &&
            <>
                
            </>
        }
    </WrapperRegisterProperty>
}

export default RegisterPropertyPage