import { useEffect, useState } from "react";
import NavAdmin from "../../components/nav-admin/NavAdmin"
import theme from "../../theme";
import WrapperAdmin from "../admin/Admin.styles"
import { styled } from 'styled-components';
import { deleteFlag, getActualPriceOfHighlight, getDocumentsByMonthAndYear, getFlagsByMonthAndYear, setFlagToApproved, setFlagToPending } from "../../hooks/firebase";
import { RxReload } from "react-icons/rx";
import { FaTrash } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { IoWarning } from "react-icons/io5";

const WrapperCard = styled.div`
    width: 47%;
    display: flex;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    border-radius: 20px;

    .image {
        width: calc(45% - 20px);
        margin-right: 20px;
        height: 200px;
        cursor: pointer;
        overflow: hidden; /* Garante que a imagem não ultrapasse o contêiner */
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            display: block;
        }
    }

    .property-data {
        display: flex;
        flex-direction: column;
        width: 60%;
        background-color: white;
        color: #111;
        gap: 12px;

        .top {
            display: flex;
            flex-direction: column;
        }

        .actions {
            display: flex;
            gap: 10px;

            .action {
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                padding: 17px;
                border-radius: 12px;
                color: white;
            }

            .reload {
                background-color:#FFD700;
            }

            .delete {
                background-color: #E57373;
            }

            .approve{
                background-color: #4CAF50;
            }

            .disabled{
                background-color: #6D6D6D;
                cursor: initial;
            }
        }
    }
`

const AdminReports = () => {
    const today = new Date();
    const months = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];
    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
    const currentMonth = months[today.getMonth()];
    const currentYear = today.getFullYear();
    const [month, setMonth] = useState(currentMonth); 
    const [year, setYear] = useState(currentYear);
    const [data, setData] = useState(null)

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return "Data inválida";
    
        const date = timestamp.toDate(); // Converte o Timestamp para um objeto Date
    
        return new Intl.DateTimeFormat("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false, // Usa formato 24h
        }).format(date);
    };

    const translateStatus = status => {
        if (status === "pending") return "Pendente";
        if (status === "approved") return "Aprovada"
    }

    const handleDeleteFlag = async (flagId) => {
        await deleteFlag(flagId);
        const newData = data.filter(report => report.id !== flagId); 
        setData(newData);
    };

    const handleUpdateFlagToPending = async (flagId) => {
        await setFlagToPending(flagId);
    
        const newData = data.map(report => {
            if (report.id === flagId) 
                return { ...report, status: "pending" }; 
            
            return report; 
        });
    
        setData(newData);
    };

    const handleUpdateFlagToApprove = async (flagId) => {
        await setFlagToApproved(flagId);
    
        const newData = data.map(report => {
            if (report.id === flagId) 
                return { ...report, status: "approved" }; 
            
            return report; 
        });
    
        setData(newData);
    };


    useEffect(() => {
        const monthNumber = months.indexOf(month)+1;
        async function load() {
            let result = await getFlagsByMonthAndYear(monthNumber, year)
            setData(result)
        }
        load()
        
    }, [month, year]);


    return <WrapperAdmin>
        <NavAdmin />
        <div className="main">
            <span className="header">
                <span className="title">Denúncias</span>
                <div className="filter">
                    <select value={month} onChange={(e) => setMonth(e.target.value)}>
                        {months.map((m) => (
                            <option key={m} value={m}>{m}</option>
                        ))}
                    </select>
                    /
                    <select value={year} onChange={(e) => setYear(e.target.value)}>
                        {years.map((y) => (
                            <option key={y} value={y}>{y}</option>
                        ))}
                    </select>
                </div>
            </span>
            <div className="body">
                {data != null && data.map(report => {
                    return <WrapperCard>
                        <a className="image" href={`/imovel/${report.property}`} target="_blank">
                            <img src={report.propertyImage} alt="" />
                        </a>
                        <div className="property-data">
                            <div className="top">
                                <span className="line">
                                    Denunciante: <strong>{report.reporter}</strong>
                                </span>
                                <span className="line">
                                    Momento da Denúncia: <strong>{formatTimestamp(report.moment)}</strong>
                                </span>
                                <span className="line">
                                    Status: <strong>{translateStatus(report.status)}</strong>
                                </span>
                            </div>
                            <div className="actions">
                                {report.status === "approved" ?
                                    <div className="action reload" onClick={() => handleUpdateFlagToPending(report.id)}>
                                        <RxReload />
                                    </div>  
                                    :
                                    <div className="action disabled">
                                        <RxReload />
                                    </div>
                                }
                                
                                <div className="action delete" onClick={() => handleDeleteFlag(report.id)}>
                                    <FaTrash />
                                </div>  
                                {report.status === "pending" ?
                                    <div className="action approve" onClick={() => handleUpdateFlagToApprove(report.id)}>
                                        <FaCheck />
                                    </div>    
                                    :
                                    <div className="action disabled">
                                        <FaCheck />
                                    </div>
                                }
                            </div>
                        </div>
                    </WrapperCard>
                })}
            </div>
        </div>
    </WrapperAdmin>
}

export default AdminReports