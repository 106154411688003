import { Fragment, useEffect, useState } from "react";
import NavAdmin from "../../components/nav-admin/NavAdmin";
import theme from "../../theme";
import WrapperAdmin from "../admin/Admin.styles";
import { styled } from "styled-components";
import { getAllBlogPosts, getMiscDataByType, saveMiscById, updateBlogById } from "../../hooks/firebase";
import { FaCheck } from "react-icons/fa6";

const WrapperCard = styled.div`
    width: calc(98% - 10%);
    display: flex;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    border-radius: 20px;
    flex-direction: column;
    padding: 5%;
    gap: 30px;

    .data {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 10px;

        .line {
            display: flex;

            .line-item {
                width: 20%;
            }

            input {
                width: calc(80% - 30px);
                padding: 5px 15px;
                outline: none;
                border: 1px solid #111;
                border-radius: 10px;
            }
        }

        .line-content {
            div {
                margin-top: 30px;
                min-height: 400px;
                width: 96%;
                padding: 2%;
                border-radius: 10px;
                outline: none;
                border: 1px solid #111;
                white-space: pre-wrap; /* Mantém quebras de linha visíveis */
                overflow-wrap: break-word;
            }
        }
    }

    .actions {
        display: flex;
        gap: 10px;

        .action {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            padding: 17px;
            border-radius: 12px;
            color: white;
        }

        .approve {
            background-color: ${theme.backgroundColor.green};
        }
    }
`;

const BlogComponent = ({ blog, id, handleSave }) => {
    const [data, setData] = useState(blog);

    const handleChange = (field, value) => {
        setData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        handleChange("title", newTitle);
        // Gerar o slug automaticamente com base no título
        const newSlug = generateSlug(newTitle);
        handleChange("slug", newSlug);
    };

    const generateSlug = (title) => {
        // Função para transformar o título em slug
        return title
            .toLowerCase()                       // Converte para minúsculas
            .replace(/[^a-z0-9\s-]/g, '')         // Remove caracteres não alfanuméricos
            .replace(/\s+/g, '-')                 // Substitui espaços por hífens
            .replace(/-+/g, '-');                 // Remove hífens duplicados
    };

    return (
        <WrapperCard key={id}>
            <div className="data">
                <span className="line">
                    <span className="line-item">Endereço:</span>
                    <input
                        type="text"
                        value={data.address}
                        onChange={handleTitleChange} // Chamando a função para atualizar título e slug
                    />
                </span>
                <span className="line">
                    <span className="line-item">CNPJ:</span>
                    <input
                        type="text"
                        value={data.cnpj}
                        onChange={(e) => handleChange("cnpj", e.target.value)}
                    />
                </span>
                <span className="line">
                    <span className="line-item">Email:</span>
                    <input
                        type="text"
                        value={data.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                    />
                </span>
                <span className="line">
                    <span className="line-item">Whatsapp:</span>
                    <input
                        type="text"
                        value={data.whatsapp}
                        onChange={(e) => handleChange("whatsapp", e.target.value)}
                    />
                </span>
                <span className="line">
                    <span className="line-item">Copy:</span>
                    <input
                        type="text"
                        value={data.copy}
                        onChange={(e) => handleChange("copy", e.target.value)}
                    />
                </span>
            </div>
            <div className="actions">
                <div className="action approve" onClick={() => handleSave(id, data)}>
                    <FaCheck />
                </div>
            </div>
        </WrapperCard>
    );
};


const AdminMisc = () => {
    const [Misc, setMiscs] = useState({});

    useEffect(() => {
        async function load() {
            let result = await getMiscDataByType();
            setMiscs(result);
        }
        load();
    }, []);

    const handleSaveBlog = async (id, updatedBlog) => {
        setMiscs((prevBlogs) => ({
            ...prevBlogs,
            [id]: updatedBlog,
        }));
        //console.log(updatedBlog)
        await saveMiscById(id, updatedBlog)
    };

    return (
        <WrapperAdmin>
            <NavAdmin />
            <div className="main">
                <span className="header">
                    <span className="title">Configurações gerais</span>
                </span>
                <div className="body">
                    {Object.entries(Misc).map(([id, blog]) => (
                        <BlogComponent
                            key={id}
                            id={id}
                            blog={blog}
                            handleSave={handleSaveBlog}
                        />
                    ))}
                </div>
            </div>
        </WrapperAdmin>
    );
};

export default AdminMisc;
