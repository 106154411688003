import { useContext, useEffect, useState } from "react";
import WrapperNotifications from "./Notifications.styles";
import { getNotifications } from "../../hooks/firebase";
import { AuthContext } from "../../context/AuthContext";
import NavBar from "../../components/nav-bar/NavBar";
import WrapperMyData from './../my-data/MyData.styles';
import { NotificationCard } from "../../components/notificationCard/NotificationCard";

const Notifications = () => {
    const [data, setData] = useState([])
    const {userData} = useContext(AuthContext)
    
    useEffect(() => {
        if (userData !== null) {
            getNotifications(userData.uid).then(result => {
                setData(result.data.notifications);
            }).catch(error => {
                console.error("Erro ao buscar notificações:", error);
            });
        }
    }, [userData]);
    


    return <WrapperMyData nothing={data.length === 0}>
        <NavBar />
        <div className="notifications">
            {data && data.length === 0 ?
                <span className="nothing">
                    Nenhuma notificação no momento, continue explorando nosso site em busca do seu futuro lar dos sonhos ou aguarde pelo comprador ideal para o seu imóvel :)
                </span>
                :
                data.map((notification, index) => {
                    return (
                        <NotificationCard 
                            id={notification.id}
                            key={notification.id}
                            chatid={notification.data.chatId}
                            photoURL={notification.data.userProfile}
                            name={notification.data.userName}
                            message={notification.action}
                            createdAt={notification.createdAt}
                        />
                    )
                })
                
            }
        </div>
    </WrapperMyData>
}

export default Notifications