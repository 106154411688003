import styled from "styled-components";
import theme from "../../theme";

const WrapperAdmin = styled.div`
    display: flex;
    background-color: #111;
    padding: 5vh 13%;
    height: 90vh;
    gap: 40px;

    .main {
        width: calc(77% - 40px);
        display: flex;
        flex-direction: column;
        overflow: auto;
        
        
        .header {
            margin-bottom: 120px;
            width: 100%;
            color: white;
            font-size: 30px;
            display: flex;
            justify-content: space-between;
            font-family: ${theme.fontFamily.bold};


            select {
                background-color: rgba(0,0,0,0);
                font-size: 30px;
                border: none;
                color: white;
                outline: none;

                option {
                    color: #111;
                    font-size: 20px;

                    &:hover{
                        background-color: #111;
                        color: white;
                    }
                }
            }

            .filter {
                display: flex;
                align-items: center;

                .add{
                    margin-left: 20px;
                    margin-top: 5px;
                    cursor: pointer;
                }
            }
        }

        .body {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 3%;
        }
    }
`

export default WrapperAdmin