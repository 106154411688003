import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase"; // Importa a instância do Firestore configurada

// Função para retornar todos os documentos da coleção 'plans'
const getAllPlans = async () => {
  try {
    // Referência à coleção 'plans' no Firestore
    const plansCollectionRef = collection(db, "plans");

    // Busca todos os documentos na coleção 'plans'
    const querySnapshot = await getDocs(plansCollectionRef);

    // Extrai os dados de cada documento retornado
    const plansList = querySnapshot.docs.map(doc => ({
      id: doc.id,  // ID do documento
      ...doc.data() // Dados do documento
    }));

    return plansList; // Retorna a lista de planos
  } catch (error) {
    console.error("Erro ao buscar planos:", error);
    return [];
  }
};

export default getAllPlans;
