import React, { useContext, useState, useRef } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';
import { MdContentCopy } from 'react-icons/md';
import { IoBedOutline, IoCarOutline } from 'react-icons/io5';
import { FaShower } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';
import { addDoc, collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../hooks/firebase';
import { sendNewAlertMessage } from './../../hooks/firebase';
import Property from '../property-map/Property';

const mapContainerStyle = {
  height: "100%",
  width: "100%" 
};

const WrapperInfoCard = styled.div`
  img {
    margin-bottom: 10px;
  }

  .location {
    font-family: ${theme.fontFamily.medium};
  }

  .room-info {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }

  .price {
    font-family: ${theme.fontFamily.medium};
    margin-top: 20px;
  }

  .actions {
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 80%;

    ${props => props.showWhatsapp ? `
        justify-content: space-between;
    ` : `
        justify-content: center;
    `}

    .whats {
      color: ${theme.fontColor.green};
    }

    .chat {
      padding: 5px 15px;
      background: ${theme.fontColor.green};
      color: white;
      border-radius: 5px;
    }
  }
`;

const MyMapComponent = (props) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [clickedMarker, setClickedMarker] = useState(null);
  const { currentUser, userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const infoWindowRef = useRef(null);
  const closeTimerRef = useRef(null);

  const formatPrice = (price) => {
    return price.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2
    });
  };

  const createCustomIcon = (price, isActive = false) => {
    const formattedPrice = (parseInt(price) / 100).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).replace(",00", "");

    const width = Math.max(40, formattedPrice.length * 7 + 20);
    const height = 50;
    const fontFamily = theme.fontFamily.normal
    const fillColor = isActive ? '#F32538' : theme.fontColor.green;

    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
        <path d="M 5 18
                 A 20 20 0 0 1 20 5 
                 L ${width - 20} 5 
                 A 20 20 0 0 1 ${width - 5} 18 
                 L ${width - 5} 30 
                 A 20 20 0 0 1 ${width - 20} 42 
                 L ${width / 2 + 7} 42 
                 L ${width / 2} 50 
                 L ${width / 2 - 7} 42 
                 L 20 42 
                 A 20 20 0 0 1 5 30 
                 Z"
              fill="${fillColor}" stroke="white" stroke-width="2"/>

        <text x="${width / 2}" y="28" text-anchor="middle" 
              font-size="13" 
              font-family="${fontFamily || 'Arial, sans-serif'}" 
              font-weight="bold" 
              fill="white">
          ${formattedPrice}
        </text>
      </svg>
    `;

    return {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
        scaledSize: { width, height },
        anchor: { x: width / 2, y: height },
    };
  };

  const handleMarkerClick = (place) => {
    if (selectedPlace?.id === place.id && clickedMarker?.id === place.id) {
      setSelectedPlace(null);
      setClickedMarker(null);
    } else {
      setSelectedPlace(place);
      setClickedMarker(place);
    }
  };

  const handleMarkerMouseOver = (place) => {
    if (!clickedMarker) {
      setSelectedPlace(place);
    }
  };

  const handleMarkerMouseOut = () => {
    if (!clickedMarker) {
      setSelectedPlace(null);
    }
  };

  const handleCloseInfoWindow = () => {
    setSelectedPlace(null);
    setClickedMarker(null);
  };

  const handleCreateChat = () => {
    createChat().then(chatId => {
      if (chatId) {
        navigate(`/chat/${chatId}`)
      } 
    })
  };

  const createChat = async () => {
    try {
      const user = userData;
      const data = selectedPlace;
      
      if (user.uid === data.ownerId) {
        alert("Você não pode abrir um chat para seu próprio anúncio");
        return false;
      }
  
      const userRef = doc(db, "users", data.ownerId);
      const userOwner = (await getDoc(userRef)).data();
  
      const chatQuery = query(
        collection(db, "chats"),
        where("propertyId", "==", data.id),
        where("ownerId", "==", data.ownerId),
        where("participantId", "==", user.uid)
      );
  
      const querySnapshot = await getDocs(chatQuery);
  
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].id;
      } else {
        const novoChat = await addDoc(collection(db, "chats"), {
          propertyId: data.id,
          ownerId: data.ownerId,
          ownerName: userOwner?.name,
          participantId: user.uid,
          participant: { name: userData.name },
          body: [],
        });
  
        sendNewAlertMessage({
          action: "Enviou uma nova mensagem para você",
          data: {
            chatId: novoChat.id,
            userName: user.name,
            userProfile: user.photoURL || "",
          },
          ownerId: user.uid === data.ownerId ? user.uid : data.ownerId,
          type: "CHAT",
        });
        return novoChat.id; 
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={props.center}
      zoom={12}
    >
      {props.properties && props.properties.map(place => (
        <Marker 
          key={place.id}
          position={{ lat: place.coords.latitude, lng: place.coords.longitude }}
          icon={createCustomIcon(place.price, selectedPlace?.id === place.id)}
          //title={place.name}
          onClick={() => handleMarkerClick(place)}
          onMouseOver={() => handleMarkerMouseOver(place)}
          onMouseOut={handleMarkerMouseOut}
        />
      ))}

      {selectedPlace && (
        <Property 
          data={selectedPlace}
          closeFn={handleCloseInfoWindow}
          onMouseEnter={() => {
            if (closeTimerRef.current) {
              clearTimeout(closeTimerRef.current);
            }
          }}
          onMouseLeave={() => {
            if (!clickedMarker) {
              closeTimerRef.current = setTimeout(() => {
                handleCloseInfoWindow();
              }, 300);
            }
          }}
        />
      )}
    </GoogleMap>
  );
};

export default MyMapComponent;