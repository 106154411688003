import { useEffect, useState } from "react";
import NavAdmin from "../../components/nav-admin/NavAdmin";
import theme from "../../theme";
import WrapperAdmin from "../admin/Admin.styles";
import { styled } from "styled-components";
import { getAllUsers, toggleDefineAnUserAsAdmin, toggleFreeTrial } from "../../hooks/firebase";
import { FaUserLarge } from "react-icons/fa6";
import { MdAdminPanelSettings } from "react-icons/md";
import { AiOutlineUserDelete } from "react-icons/ai";
import { RiTestTubeFill } from "react-icons/ri";
import Switch from "react-ios-switch";

const WrapperUser = styled.div`
    width: calc(90% - 20px);
    padding: 0 5%;
    display: flex;
    align-items: center;
    border: 1px solid snow;
    margin-bottom: 10px;
    border-radius: 7px;
    color: snow;

    .image {
        width: 7%;
        margin-right: 20px;
        padding: 7px 0px;
        position: relative;

        .admin-icon {
            position: absolute;
            width: 30px;
            margin-top: -10px;
            margin-left: -10px;
            background-color: snow;
            padding: 3px;
            border-radius: 100px;
            color: #111;
        }

        svg {
            color: snow;
            width: 100%;
            height: auto;
            font-size: 30px;
        }

        img {
            min-width: 100%;
            max-width: 100%;
        }
    }

    .name, .email {
        font-size: 12px;
    }

    .name {
        width: 17%;
    }

    .email {
        width: 12%;
    }

    .actions {
        width: 30%;
        display: flex;
        margin-left: auto;
        gap: 17px;
        align-items: center;
        justify-content: flex-end;

        .switch-container {
            display: flex;
            align-items: center;
            gap: 5px;
            
            svg {
                font-size: 20px;
                color: snow;
            }
        }

        .delete-btn {
            cursor: pointer;
            transition: 0.3s;
            
            &:hover {
                opacity: 0.7;
            }
            
            svg {
                font-size: 30px;
            }
        }
    }
`;

const AdminUsers = () => {
    const [data, setData] = useState(null);
    const [loadingUser, setLoadingUser] = useState(null);

    async function load() {
        let result = await getAllUsers();
        setData(result);
    }
    
    useEffect(() => {
        load();
    }, []);

    async function handleToggleAdmin(userId, currentValue) {
        setLoadingUser(userId);
        await toggleDefineAnUserAsAdmin(userId);
        await load();
        setLoadingUser(null);
    }

    async function handleToggleFreeTrial(userId, currentValue) {
        setLoadingUser(userId)
        await toggleFreeTrial(userId)
        await load()
        setLoadingUser(null)
    }

    return (
        <WrapperAdmin>
            <NavAdmin />
            <div className="main">
                <span className="header">
                    <span className="title">Usuários</span>
                </span>
                <div className="body">
                    {data !== null && data.map(user => (
                        <WrapperUser key={user.id}>
                            <div className="image">
                                {user.role === "admin" && <MdAdminPanelSettings className="admin-icon" />}
                                {user.photoURL ? 
                                    <img src={user.photoURL} alt={user.name} />
                                    :
                                    <FaUserLarge />
                                }
                            </div>
                            <span className="name">{user.name}</span>
                            <span className="email">{user.email}</span>
                            <div className="actions">
                                <div className="switch-container" title="Alternar entre super usuário">
                                    <MdAdminPanelSettings />
                                    <Switch
                                        checked={user.role === "admin"}
                                        onChange={() => handleToggleAdmin(user.id, user.role === "admin")}
                                        disabled={loadingUser === user.id}
                                    />
                                </div>
                                
                                <div className="switch-container" title="Teste grátis">
                                    <RiTestTubeFill />
                                    <Switch
                                        checked={user.freeTrialUsed === true}
                                        onChange={() => handleToggleFreeTrial(user.id, user.freeTrialUsed === true)}
                                        disabled={loadingUser === user.id}
                                    />
                                </div>
                                
                                <div className="delete-btn">
                                    <AiOutlineUserDelete />
                                </div>
                            </div>
                        </WrapperUser>
                    ))}
                </div>
            </div>
        </WrapperAdmin>
    );
}

export default AdminUsers;