import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';

const PrivateRouteADM = ({ children }) => {
  const { userData } = useContext(AuthContext);
  //console.log(userData)

  // Verifica se o usuário está autenticado
  return userData.role === "admin" ? children : <Navigate to="/" />;
};

export default PrivateRouteADM;