import styled from "styled-components";
import { MdContentCopy } from "react-icons/md";
import { IoBedOutline } from "react-icons/io5";
import { FaCheck, FaShower } from "react-icons/fa";
import { IoCarOutline } from "react-icons/io5";
import { PiHeart } from "react-icons/pi";
import theme from './../../theme';
import { useNavigate } from "react-router-dom";
import { div } from "framer-motion/client";
import Switch from 'react-ios-switch';
import { useState } from "react";
import { activatePropertyPlan, changeStatusAnPropertyById, deleteProperty, toggleDeleteProperty, togglePropertyActive, updatePropertyOwnerByEmail } from "../../hooks/firebase";
import { PiFloppyDiskBackFill } from "react-icons/pi";
import { TbReload } from "react-icons/tb";
import { FaXmark } from "react-icons/fa6";


const WrapperHouseAdvertisementCard = styled.div`
    width: calc(30% - 10px);
    margin-right: 10px;
    border-radius: 13px;
    margin-bottom: 30px;
    border: 2px solid #dfe0e6;
    display: flex;
    flex-direction: column;
    background-color: white;

    .img-container {
        width: 100%;
        height: 230px;
        cursor: pointer;
        overflow: hidden; /* Garante que a imagem não ultrapasse o contêiner */
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover; /* Garante que a imagem preencha o contêiner sem distorção */
            border-top-right-radius: 13px;
            border-top-left-radius: 13px;
            display: block;
        }
    }

    .property-info {
        display: flex;
        flex-direction: column;
        padding: 17px;
        

        .property-title {
            font-weight: 600;
            cursor: pointer;
            font-size: 1rem !important;
            font-family: ${theme.fontFamily.regular};
            color: #2b353f;
        }

        .property-road {
            color: #6f7688;
            margin: 5px 0px;
            font-weight: 600;
            font-size: .845rem !important;
        }

        .property-space {
            margin-bottom: 27px;
        }

        .room-info{
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .info {
                width: 33%;
                display: flex;
                align-items: center;
                gap: 2px;
                font-size: .875rem !important;
                font-family: ${theme.fontFamily.medium};
                color: #586880;

                &.alqueiro {
                    width: 100%;
                }

                svg {
                    width: 1.2rem !important;
                    height: 1.2rem !important;
                }

                &:nth-child(1){
                    justify-content: flex-start;
                }

                &:nth-child(2){
                    justify-content: center;
                }

                &:nth-child(3){
                    justify-content: flex-end;

                    transform: translateX(-20px);
                }

                &:nth-child(4){
                    justify-content: flex-start;
                    margin-top: 10px;
                }
            }
        }

        .price-action {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            justify-self: flex-end;

            .price-container{
                display: flex;
                flex-direction: column;

                .base-price{
                    font-family: ${theme.fontFamily.medium};
                    font-weight: 600;
                    font-size: 1rem !important;
                }

                .price {
                    color: #5e6578;
                    font-family: ${theme.fontFamily.medium};
                    font-size: .875rem !important;
                    font-weight: 600;
                }
            }

            .action {

                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    border-radius: 15px;
                    border: 2px solid #dfe0e6;
                    padding: 10px;
                    color: ${theme.fontColor.green};
                    font-size: 30px;
                    transition: background .3s, color .3s, border .3s;
                    cursor: pointer;

                    &:hover{
                        background-color: ${theme.fontColor.green};
                        border-color: ${theme.fontColor.green};
                        color: white;
                    }
                }
            }
        }

        .actionsB {
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 20px;

            .action2 {
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                padding: 17px;
                border-radius: 12px;
                color: white;
            }

            .reload {
                background-color:#FFD700;
            }

            .delete {
                background-color: #E57373;
            }

            .approve{
                background-color: #4CAF50;
            }

            .disabled{
                background-color: #6D6D6D;
                cursor: initial;
            }
        }


        .actions {
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .action {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .attributeToUser{
                display: flex;
                align-items: center;
                gap: 7px;

                input {
                    outline: none;
                    border: none;
                    border: 2px solid gray;
                    border-radius: 300px;
                }

                svg {
                    font-size: 21px;
                    cursor: pointer;
                }
            }

            .reloadPlan{
                display: flex;
                justify-content: flex-start;
                cursor: pointer;
                
                svg {
                    color: white;
                    padding: 10px;
                    border-radius: 10px;
                    background-color: ${theme.backgroundColor.green};
                }
            }
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        width: 100%;

        .property-info {

            .room-info {
                margin-top: 0;

                .info {
                    width: 25%;
                    margin-top: 20px !important;
                    font-size: 20px;
                    justify-content: flex-start !important;

                    svg {
                        font-size: 20px;
                    }

                    &:nth-child(1){
                        justify-content: flex-start;
                    }

                    &:nth-child(2){
                        justify-content: flex-end;
                        svg {
                            font-size: 22px;
                        }
                    }

                    &:nth-child(3){ 
                        transform: none;
                        justify-content: flex-start;
                    }

                    &:nth-child(4){
                        justify-content: flex-end;
                    }
                }
            }
        }
    }

    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.mobile_2}) {
        
        .img-container {
            height: 190px;
        }
        
        .property-info {

        .property-title {
            font-size: .75rem !important;
        }

        .room-info {

            .info {
                width: 49%;
                justify-content: flex-start !important;
                transform: none !important;
                font-size: .85rem !important;

                &:nth-child(2){
                    
                    svg {
                        margin-left: 3px;
                    }
                }
            }
        }

        .price-action {
            font-size: 1rem;

            .price-container {
                .base-price {
                    font-size: .9rem !important;
                }
                .price {
                    font-size: .9rem !important;
                }
            }

            .action {
                svg {
                    font-size: 1.35rem;
                }
            }
        }
        }
    } 

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        
        .property-info {

            .property-title {
                font-size: .85rem !important;
            }

            .room-info {

                .info {
                    width: 49%;
                    justify-content: flex-start !important;
                    transform: none !important;
                    font-size: .85rem !important;

                    &:nth-child(2){
                        
                        svg {
                            margin-left: 3px;
                        }
                    }
                }
            }

            .price-action {
                font-size: 1rem;

                .price-container {
                    .base-price {
                        font-size: .9rem !important;
                    }
                    .price {
                        font-size: .9rem !important;
                    }
                }

                .action {
                    svg {
                        font-size: 1.35rem;
                    }
                }
            }
        }
    }
`

const HouseAdvertisementCardAdmin = ({data, isLaunch, load}) => { 
    const navigate = useNavigate()
    let [active, setActive] = useState(data.active)
    let [deleted, setDeleted] = useState(data.deletedAt)
    const [inputSwithEmail, setInputSwithEmail] = useState(null)
    

    const handleChangeStatus = async (newStatus) => {
        const result = await changeStatusAnPropertyById(data.id, newStatus)
        alert(`Status atualizado para ${ newStatus === "WAITING" ? "Aguardando" : "Ativo"}`)
        await load()
    }

    const handleReloadExpirationDate = () => {
        activatePropertyPlan(data.id).then(message => {
            alert(message)
        })
    }

    const handleSwitchEmail = () => {
        if (inputSwithEmail === null || inputSwithEmail === "") return;
        updatePropertyOwnerByEmail(inputSwithEmail, data.id).then(message => {
            alert(message)
            setInputSwithEmail("")
        })
    }   

    const formatToBRL = (value) => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value/100);
    };

    function handleToggleActive() {
        const newActiveStatus = !active;
        setActive(newActiveStatus);
        togglePropertyActive(data.id)
    }

    function handleToggleDelted() {
        const newActiveStatus = !deleted;
        setDeleted(newActiveStatus);
        toggleDeleteProperty(data.id)
    }


    return (
        <WrapperHouseAdvertisementCard>
            <a className="img-container" target="_blank" href={"/imovel/"+data.id}>
                <img src={data.images[0]} alt={data.title} />
            </a>
            <div className="property-info">
                <span className="property-title">{data.id}</span>
                <span className="property-title">{data.street}, {data.number}</span>
                <span className="property-road">{data.city}</span>
                <div className="room-info">
                    {data.squareMeter &&
                        <span className="info">
                            <MdContentCopy />
                            {data.squareMeter} m²
                        </span>
                    }
                    {["Área Comercial", "Terreno", "Terreno Em Condomínio", "Terreno Comercial"].includes(data.type) &&
                        <span className="info">
                            <MdContentCopy />
                            {data.squareConstruction} m²
                        </span>
                    }
                    {data.type === "Sítio/Fazenda" &&
                        <span className="info alqueiro">
                            <MdContentCopy />
                            {data.alqueiro} alqueiro
                        </span>
                    }
                    {data.bedroom && 
                        <span className="info">
                            <IoBedOutline />
                            {data.bedroom}
                        </span>
                    }
                    {data.bathroom &&
                        <span className="info">
                            <FaShower />
                            {data.bathroom}
                        </span>
                    }
                    {data.garage && 
                        <span className="info">
                            <IoCarOutline />
                            {data.garage}
                        </span>
                    }
                </div>
                {["Área Comercial", "Galpão/Armazém", "Terreno", "Terreno Em Condomínio", "Terreno Comercial", "Sítio/Fazenda", "Prédio Comercial"].includes(data.type) &&
                    <div className="property-space"></div>
                }
                <div className="price-action">
                    <div className="price-container">
                        <span className="base-price">{formatToBRL(data.price)}</span>
                    </div>
                </div>
                {!isLaunch ?
                    <div className="actions">
                        <div className="action">
                            Ativo: <Switch
                                checked={active}
                                onChange={handleToggleActive}
                                onColor={theme.fontColor.green}
                                className="switch"
                            />
                        </div>
                        <div className="action">
                            Deletado: <Switch
                                checked={deleted}
                                onChange={handleToggleDelted}
                                onColor={theme.fontColor.green}
                                className="switch"
                            />
                        </div>
                        <div className="attributeToUser">
                            <input placeholder="Email" value={inputSwithEmail} type="text" onChange={e => setInputSwithEmail(e.currentTarget.value)} />
                            <PiFloppyDiskBackFill onClick={handleSwitchEmail} />
                        </div>
                        <div className="reloadPlan">
                            <TbReload onClick={handleReloadExpirationDate} title="Recarregar plano" />
                        </div>
                    </div>
                    : 
                    <div className="actionsB">
                        {data.status !== "WAITING" ?
                            <div className="action2 delete" onClick={() => handleChangeStatus("WAITING")}>
                                <FaXmark />
                            </div>     
                            :
                            <div className="action2 disabled" >
                                <FaXmark />
                            </div> 
                        } 
                        {data.status !== "ACTIVE" ?
                            <div className="action2 approve" onClick={() => handleChangeStatus("ACTIVE")}>
                                <FaCheck />
                            </div>    
                            :
                            <div className="action2 disabled" >
                                <FaCheck />
                            </div>
                        }	
                    </div>
                }
            </div>
        </WrapperHouseAdvertisementCard>
    );
}

export default HouseAdvertisementCardAdmin;
