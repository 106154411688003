import { useEffect, useState } from "react";
import NavAdmin from "../../components/nav-admin/NavAdmin"
import theme from "../../theme";
import WrapperAdmin from "./Admin.styles"
import { styled } from 'styled-components';
import { getDocumentsByMonthAndYear } from "../../hooks/firebase";

const WrapperCard = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    .card-title{
        font-size: 20px;
        color: white;
    }

    .content{
        width: 60%;
        background-color: white;
        text-align: center;
        color: black;
        font-size: 50px;
        padding: 10% 20%;
        border-radius: 30px;
        font-family: ${theme.fontFamily.bold};
    }
`

const WrapperCard2 = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    .card-title{
        font-size: 20px;
        color: white;
    }

    .content{
        width: 80%;
        background-color: white;
        text-align: center;
        color: black;
        font-size: 18px;
        padding: 15% 10%;
        border-radius: 30px;
        font-family: ${theme.fontFamily.bold};

        .line {
            display: flex;
            justify-content: space-between;
            width: 100%;
            white-space: nowrap;
        }

        .city-name {
            flex-grow: 1;
            text-align: left;
        }

        .dots {
            flex-grow: 2;
            text-align: center;
            overflow: hidden;
        }

        .city-count {
            flex-grow: 1;
            text-align: right;
        }
    }
`

const AdminHome = () => {
    const today = new Date();
    const months = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
    const currentMonth = months[today.getMonth()];
    const currentYear = today.getFullYear();
    const [month, setMonth] = useState(currentMonth); 
    const [year, setYear] = useState(currentYear);
    const [freeCountDeleted, setFreeCountDeleted] = useState(0)
    const [freeCountActive, setFreeCountActive] = useState(0)
    const [freeCountStoped, setFreeCountStoped] = useState(0)
    const [highlightCountDeleted, setHighlightCountDeleted] = useState(0)
    const [highlightCountStoped, setHighlightCountStoped] = useState(0)
    const [highlightCountActive, setHighlightCountActive] = useState(0)
    const [topCities, setTopCities] = useState(null)
    const [topCategories, setTopCategories] = useState(null)
    const [topCitiesFree, setTopCitiesFree] = useState(null)
    const [topCategoriesFree, setTopCategoriesFree] = useState(null)

    function topCidades(imoveis) {
        const contagem = {};
    
        imoveis.forEach(imovel => {
            if (imovel.city) {
                const cidade = imovel.city;
                contagem[cidade] = (contagem[cidade] || 0) + 1;
            }
        });
    
        const cidadesOrdenadas = Object.entries(contagem)
            .sort((a, b) => b[1] - a[1]) 
            .slice(0, 10); 
    
        return cidadesOrdenadas//.map(([cidade, qtd]) => `${cidade} - ${qtd}`);
    }

    function topCategorias(imoveis) {
        const contagem = {};
    
        imoveis.forEach(imovel => {
            if (imovel.type) {
                const cidade = imovel.type;
                contagem[cidade] = (contagem[cidade] || 0) + 1;
            }
        });
    
        const cidadesOrdenadas = Object.entries(contagem)
            .sort((a, b) => b[1] - a[1]) 
            .slice(0, 10); 
    
        return cidadesOrdenadas//.map(([cidade, qtd]) => `${cidade} - ${qtd}`);
    }

    useEffect(() => {
        const monthNumber = months.indexOf(month)+1;
        getDocumentsByMonthAndYear(monthNumber, year).then(result => {
            var freedeleted = 0
            var freeactive = 0
            var freestoped = 0
            var highlightdeleted = 0
            var highlightstoped = 0
            var highlightactive = 0
            var propertyFree = []
            var propertyHigh = []
            result.forEach(property => {
                if (property.hasFree) {
                    if (property.deletedAt !== null) {
                        freedeleted += 1
                        return;
                    }
                    if (property.deletedAt !== null) freedeleted += 1
                    if (!property.active && property.status !== "ACTIVE") freestoped += 1
                    if (property.status === "PENDING") freestoped += 1
                    if (!property.active) freestoped += 1
                    if (property.active && property.status === "ACTIVE") freeactive += 1
                    propertyFree.push(property)
                } else {
                    if (property.deletedAt !== null) {
                        highlightdeleted += 1
                        return;
                    }
                    if (property.deletedAt !== null) highlightdeleted += 1
                    if (!property.active && property.status !== "ACTIVE") highlightstoped += 1
                    if (property.status === "PENDING") highlightstoped += 1
                    if (!property.active) highlightstoped += 1
                    if (property.active && property.status === "ACTIVE") highlightactive += 1
                    propertyHigh.push(property)
                }
            })
            setTopCategories(topCategorias(propertyHigh))
            setTopCities(topCidades(propertyHigh))
            setTopCategoriesFree(topCategorias(propertyFree))
            setTopCitiesFree(topCidades(propertyFree))
            setFreeCountDeleted(freedeleted)
            setFreeCountActive(freeactive)
            setFreeCountStoped(freestoped)
            setHighlightCountDeleted(highlightdeleted)
            setHighlightCountStoped(highlightstoped)
            setHighlightCountActive(highlightactive)
        });
    }, [month, year]);


    return <WrapperAdmin>
        <NavAdmin />
        <div className="main">
            <span className="header">
                <span className="title">Estatística</span>
                <div className="filter">
                    <select value={month} onChange={(e) => setMonth(e.target.value)}>
                        {months.map((m) => (
                            <option key={m} value={m}>{m}</option>
                        ))}
                    </select>
                    /
                    <select value={year} onChange={(e) => setYear(e.target.value)}>
                        {years.map((y) => (
                            <option key={y} value={y}>{y}</option>
                        ))}
                    </select>
                </div>
            </span>
            <div className="body">
                <WrapperCard>
                    <span className="card-title">Anúncios excluídos grátis</span>
                    <div className="content">
                        {freeCountDeleted}
                    </div>
                </WrapperCard>
                <WrapperCard>
                    <span className="card-title">Anúncios Ativos grátis</span>
                    <div className="content">
                        {freeCountActive}
                    </div>
                </WrapperCard>
                <WrapperCard>
                    <span className="card-title">Anúncios Pausados grátis</span>
                    <div className="content">
                        {freeCountStoped}
                    </div>
                </WrapperCard>
                <WrapperCard>
                    <span className="card-title">Anúncios Turbinados excluídos</span>
                    <div className="content">
                        {highlightCountDeleted}
                    </div>
                </WrapperCard>
                <WrapperCard>
                    <span className="card-title">Anúncios Turbinados Pausados</span>
                    <div className="content">
                        {highlightCountStoped}
                    </div>
                </WrapperCard>
                <WrapperCard>
                    <span className="card-title">Anúncios Turbinados Ativos</span>
                    <div className="content">
                        {highlightCountActive}
                    </div>
                </WrapperCard>
                <WrapperCard2>
                    <span className="card-title">Região anúncios turbinados</span>
                    <div className="content">
                        {topCities !== null && topCities.map(city => {
                             return (
                                <span className="line" key={city[0]}>
                                    <span className="city-name">{city[0]}</span>
                                    <span className="dots">..........................................</span>
                                    <span className="city-count">{city[1]}</span>
                                </span>
                            );
                        })}
                    </div>
                </WrapperCard2>
                <WrapperCard2>
                    <span className="card-title">Categorias anúncios turbinados</span>
                    <div className="content">
                        {topCategories !== null && topCategories.map(categorie => {
                             return (
                                <span className="line" key={categorie[0]}>
                                    <span className="city-name">{categorie[0]}</span>
                                    <span className="dots">..........................................</span>
                                    <span className="city-count">{categorie[1]}</span>
                                </span>
                            );
                        })}
                    </div>
                </WrapperCard2>
                <WrapperCard2>
                    <span className="card-title">Região anúncios Grátis</span>
                    <div className="content">
                        {topCitiesFree !== null && topCitiesFree.map(categorie => {
                             return (
                                <span className="line" key={categorie[0]}>
                                    <span className="city-name">{categorie[0]}</span>
                                    <span className="dots">..........................................</span>
                                    <span className="city-count">{categorie[1]}</span>
                                </span>
                            );
                        })}
                    </div>
                </WrapperCard2>
                <WrapperCard2>
                    <span className="card-title">Categorias anúncios Grátis</span>
                    <div className="content">
                        {topCategoriesFree !== null && topCategoriesFree.map(categorie => {
                             return (
                                <span className="line" key={categorie[0]}>
                                    <span className="city-name">{categorie[0]}</span>
                                    <span className="dots">..........................................</span>
                                    <span className="city-count">{categorie[1]}</span>
                                </span>
                            );
                        })}
                    </div>
                </WrapperCard2>
            </div>
        </div>
    </WrapperAdmin>
}

export default AdminHome