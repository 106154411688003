import { useEffect, useState } from "react";
import NavAdmin from "../../components/nav-admin/NavAdmin"
import theme from "../../theme";
import WrapperAdmin from "../admin/Admin.styles"
import { styled } from 'styled-components';
import { getActualPriceOfHighlight, getDocumentsByMonthAndYear } from "../../hooks/firebase";
import HouseAdvertisementCardAdmin from "../../components/house_advertisement_card-admin/HouseAdvertisementCard";
import { FaPlusCircle } from "react-icons/fa";

const WrapperCard = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    .card-title{
        font-size: 20px;
        color: white;
    }

    .content{
        width: 60%;
        background-color: white;
        text-align: center;
        color: black;
        font-size: 30px;
        padding: 10% 20%;
        border-radius: 30px;
        font-family: ${theme.fontFamily.bold};
    }
`


const AdminAnuncios = () => {
    const today = new Date();
    const months = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
    const currentMonth = months[today.getMonth()];
    const currentYear = today.getFullYear();
    const [month, setMonth] = useState(currentMonth); 
    const [year, setYear] = useState(currentYear);
    const [data, setData] = useState(null)


    useEffect(() => {
        const monthNumber = months.indexOf(month)+1;
        async function load() {
            getDocumentsByMonthAndYear(monthNumber, year).then(result => {
                console.log(result[0])
                setData(result)
            });
        }
        load()
        
    }, [month, year]);


    return <WrapperAdmin>
        <NavAdmin />
        <div className="main">
            <span className="header">
                <span className="title">Anúncios</span>
                <div className="filter">
                    <select value={month} onChange={(e) => setMonth(e.target.value)}>
                        {months.map((m) => (
                            <option key={m} value={m}>{m}</option>
                        ))}
                    </select>
                    /
                    <select value={year} onChange={(e) => setYear(e.target.value)}>
                        {years.map((y) => (
                            <option key={y} value={y}>{y}</option>
                        ))}
                    </select>
                    {/* <div className="add">
                        <FaPlusCircle />
                    </div> */}
                </div>

            </span>
            <div className="body">
                {data !== null &&
                    data.map(property => {
                        return <HouseAdvertisementCardAdmin 
                            data={property}
                        />
                    })
                }
            </div>
        </div>
    </WrapperAdmin>
}

export default AdminAnuncios