import { useEffect, useState } from "react";
import NavAdmin from "../../components/nav-admin/NavAdmin";
import theme from "../../theme";
import WrapperAdmin from "../admin/Admin.styles";
import { styled } from "styled-components";
import { getAllBlogPosts, updateBlogById, deleteBlogById } from "../../hooks/firebase"; // Certifique-se de ter uma função deleteBlogById no seu Firebase hook
import { FaCheck, FaRegEdit, FaPlus } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { FaTrash } from "react-icons/fa6";

const storage = getStorage();

const WrapperCard = styled.div`
    width: calc(98% - 10%);
    display: flex;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    border-radius: 20px;
    flex-direction: column;
    padding: 5%;
    gap: 30px;

    .data {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 10px;

        .line {
            display: flex;

            .line-item {
                width: 20%;
            }

            input {
                width: calc(80% - 30px);
                padding: 5px 15px;
                outline: none;
                border: 1px solid #111;
                border-radius: 10px;
            }
        }

        .line-content {
            textarea {
                margin-top: 30px;
                resize: none;
                height: 400px;
                width: 96%;
                padding: 2%;
                border-radius: 10px;
                outline: none;
                border: 1px solid #111;
            }
        }
    }

    .image-upload {
        display: flex;
        flex-direction: column;
        gap: 10px;

        img {
            max-width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }

    .actions {
        display: flex;
        gap: 10px;

        .action {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            padding: 17px;
            border-radius: 12px;
            color: white;
        }

        .approve {
            background-color: ${theme.backgroundColor.green};
        }

        .close {
            background-color: ${theme.backgroundColor.red};
            color: black;
        }

        .delete {
            background-color: #E57373;
        }
    }
`;

const BlogComponent = ({ blog, id, handleSave, handleClose, handleDelete }) => {
    const [data, setData] = useState(blog);
    const [desktopImage, setDesktopImage] = useState(null);
    const [mobileImage, setMobileImage] = useState(null);
    const [desktopPreview, setDesktopPreview] = useState(blog.bannerDesktop || "");
    const [mobilePreview, setMobilePreview] = useState(blog.bannerMobile || "");

    const handleChange = (field, value) => {
        setData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        handleChange("title", newTitle);
        handleChange("slug", generateSlug(newTitle));
    };

    const generateSlug = (title) => {
        return title
            .toLowerCase()
            .replace(/[^a-z0-9\s-]/g, "")
            .replace(/\s+/g, "-")
            .replace(/-+/g, "-");
    };

    const uploadImage = async (file, blogId, type) => {
        if (!file || !blogId) return null;
        const storageRef = ref(storage, `blogs/${blogId}/${type}-${uuidv4()}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    };

    const handleSaveClick = async () => {
        if (!data.title || !data.body || !desktopImage || !mobileImage) {
            alert("Todos os campos devem ser preenchidos, incluindo as imagens.");
            return;
        }

        let updatedData = { ...data };

        if (updatedData.order === 1) {
            updatedData.isMain = true;
        } else {
            updatedData.isMain = false;
        }

        if (desktopImage) {
            updatedData.bannerDesktop = await uploadImage(desktopImage, id, "desktop");
            setDesktopPreview(updatedData.bannerDesktop);
        }

        if (mobileImage) {
            updatedData.bannerMobile = await uploadImage(mobileImage, id, "mobile");
            setMobilePreview(updatedData.bannerMobile);
        }

        handleSave(id, updatedData);
    };

    return (
        <WrapperCard key={id}>
            <div className="data">
                <span className="line">
                    <span className="line-item">Título:</span>
                    <input type="text" value={data.title} onChange={handleTitleChange} />
                </span>
                <span className="line">
                    <span className="line-item">Autor:</span>
                    <input type="text" value={data.author} onChange={(e) => handleChange("author", e.target.value)} />
                </span>
                <span className="line">
                    <span className="line-item">Tema:</span>
                    <input type="text" value={data.theme} onChange={(e) => handleChange("theme", e.target.value)} />
                </span>
                <span className="line">
                    <span className="line-item">Ordem:</span>
                    <input type="number" value={data.order} onChange={(e) => handleChange("order", e.target.value)} />
                </span>
                <span className="line">
                    <span className="line-item">Slug:</span>
                    <input type="text" value={data.slug} disabled />
                </span>
                <span className="line-content">
                    <textarea value={data.body.replace(/\\n/g, "\n")} onChange={(e) => {handleChange("body", e.target.value)}} />
                </span>
                <div className="image-upload">
                    <span>Banner Desktop:</span>
                    <input type="file" onChange={(e) => setDesktopImage(e.target.files[0])} />
                    {desktopPreview && <img src={desktopPreview} alt="Preview Desktop" />}
                    <span>Banner Mobile:</span>
                    <input type="file" onChange={(e) => setMobileImage(e.target.files[0])} />
                    {mobilePreview && <img src={mobilePreview} alt="Preview Mobile" />}
                </div>
            </div>
            <div className="actions">
                <div className="action delete" onClick={() => handleDelete(blog)}>
                    <FaTrash /> Excluir
                </div>
                <div className="action approve" onClick={handleSaveClick}>
                    <FaCheck /> 
                </div>
                <div className="action close" onClick={() => handleClose(id)}>
                    <LiaTimesSolid />
                </div>
            </div>
        </WrapperCard>
    );
};

const AdminBlogs = () => {
    const [blogs, setBlogs] = useState({});
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        async function load() {
            let result = await getAllBlogPosts();
            setBlogs(result);
        }
        load();
    }, []);

    const handleSaveBlog = async (id, updatedBlog) => {
        const blogId = updatedBlog.id || id;
    
        // Atualiza o estado local com o updatedBlog
        setBlogs((prevBlogs) => ({
            ...prevBlogs,
            [blogId]: updatedBlog,
        }));
    
        // Salva no Firebase
        await updateBlogById(blogId, { ...updatedBlog, body: JSON.stringify(updatedBlog.body) });
    
        setEditingId(null);
    
        // Atualiza a lista de blogs
        const newData = await getAllBlogPosts();
        setBlogs(newData);
    };
    

    const handleDeleteBlog = async (updatedBlog) => {
        const blogId = updatedBlog.id; // Garantir que estamos usando o ID do documento

        if (!blogId) return; // Verifica se há um id válido
        
        // Excluindo o blog do Firebase
        await deleteBlogById(blogId);

        const newData = await getAllBlogPosts();
        setBlogs(newData);
        setEditingId(null);  
        // Atualizando o estado local após a exclusão
        // setBlogs((prevBlogs) => {
        //     const updatedBlogs = { ...prevBlogs };
        //     delete updatedBlogs[blogId]; // Remove o blog do estado local
        //     return updatedBlogs;
        // });
    };

    const handleAddBlog = () => {
        const newId = uuidv4();
        setBlogs((prevBlogs) => ({
            ...prevBlogs,
            [newId]: { title: "", author: "", theme: "", slug: "", body: "", bannerDesktop: "", bannerMobile: "" },
        }));
        setEditingId(newId); 
    };

    return (
        <WrapperAdmin>
            <NavAdmin />
            <div className="main">
                <span className="header">
                    <span className="title">Blogs</span>
                    <FaPlus onClick={handleAddBlog} style={{ cursor: "pointer", marginLeft: "10px", color: "white" }} />
                </span>
                <div className="body">
                    {Object.entries(blogs).map(([id, blog]) =>
                        editingId === id ? (
                            <BlogComponent key={id} id={id} blog={blog} handleSave={handleSaveBlog} handleClose={() => setEditingId(null)} handleDelete={handleDeleteBlog} />
                        ) : (
                            <div key={id} onClick={() => setEditingId(id)} style={{ cursor: "pointer", borderBottom: "1px solid snow", order: blog.order, padding: "15px", color: "white", display: "flex", alignItems: "center", gap: "10px", width: "calc(100% - 30px - 10px)" }}>
                                <span className="index">{blog.order}</span>
                                <span>{blog.title}</span>
                                <FaRegEdit />
                            </div>
                        )
                    )}
                </div>
            </div>
        </WrapperAdmin>
    );
};

export default AdminBlogs;
